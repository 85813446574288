import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class WorkService {
    constructor(private http: HttpClient) { }

    saveDetails(jsonData: any) {
        return this.http.post<any>(`/us-en/proxy/ETService.aspx/FormMethod`, jsonData)
            .pipe(map(data => {
                return data;
            }));
    }

}
