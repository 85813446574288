<div class="block--content">
  <div class="block--filler">
    <div class="block--img">
      <img
        src="{{ thisReport.img.src }}"
        alt="{{ thisReport.img.alt }}"
        title="{{ thisReport.img.alt }}"
        width="84"
      />
    </div>
    <div class="block--desc">
      <h3>{{ thisReport.title }}</h3>
      <p>{{ thisReport.description }}</p>

      <div class="btn-group">
        <app-btn-download href="{{ thisReport.href }}" title="{{ thisReport.title }} (opens in a new window)"></app-btn-download>
        <app-btn-share
            email_id="{{ thisReport.share.email_id }}"
            web_root="{{ thisReport.share.web_root }}"
            url="{{ thisReport.share.url }}"
            social_title="{{ thisReport.share.social_title }}"
            social_linkedin="{{ thisReport.share.social_linkedin }}"
            social_body="{{ thisReport.share.social_body }}"
            email_subject="{{ thisReport.share.email_subject }}"
            email_body="{{ thisReport.share.email_body }}"
            color="{{ thisReport.share.color }}"
        ></app-btn-share>
      </div>
    </div>
  </div>
</div>
