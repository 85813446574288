import { Component, OnInit, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {

  constructor(public el: ElementRef) { }

  ngOnInit() {
  }

}
