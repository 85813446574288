/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navigation-next.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./navigation-next.component";
import * as i6 from "../globals";
var styles_NavigationNextComponent = [i0.styles];
var RenderType_NavigationNextComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavigationNextComponent, data: {} });
export { RenderType_NavigationNextComponent as RenderType_NavigationNextComponent };
export function View_NavigationNextComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "next-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "full-next animate is-disabled"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [["data-cta", "Next Arrow"], ["href", "#"], ["id", "navNext"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateTo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(4, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"] }, null), i1.ɵpad(5, 2)], function (_ck, _v) { var currVal_1 = _ck(_v, 5, 0, "fas", "angle-down"); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).renderedIconHTML; _ck(_v, 3, 0, currVal_0); }); }
export function View_NavigationNextComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navigation-next", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).checkScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_NavigationNextComponent_0, RenderType_NavigationNextComponent)), i1.ɵdid(1, 114688, null, 0, i5.NavigationNextComponent, [i6.Globals], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavigationNextComponentNgFactory = i1.ɵccf("app-navigation-next", i5.NavigationNextComponent, View_NavigationNextComponent_Host_0, {}, {}, []);
export { NavigationNextComponentNgFactory as NavigationNextComponentNgFactory };
