import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var WorkService = /** @class */ (function () {
    function WorkService(http) {
        this.http = http;
    }
    WorkService.prototype.saveDetails = function (jsonData) {
        return this.http.post("/us-en/proxy/ETService.aspx/FormMethod", jsonData)
            .pipe(map(function (data) {
            return data;
        }));
    };
    WorkService.ngInjectableDef = i0.defineInjectable({ factory: function WorkService_Factory() { return new WorkService(i0.inject(i1.HttpClient)); }, token: WorkService, providedIn: "root" });
    return WorkService;
}());
export { WorkService };
