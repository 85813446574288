import { Component, HostListener, ElementRef, OnInit, Input, ViewChild } from '@angular/core';
import { scrollToPage } from '../app.animations';
import {Globals} from '../globals';

declare let videojs: any ;

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss'],
  animations: [scrollToPage]
})
export class WorkComponent implements OnInit {

  slideOptions = { items: 1, dots: false, nav: false };
  people = [
    {
      name: `Veronica Somarriba`,
      header: `Meet our Technology Industry Practice Leader`,
      photo: {
        src: `assets/img/Veronica@2x.png`
      },
      points: [`30+ Years of Experience`, `Founding TechAssure Member`, `Widely Recognized in<br />the Industry`],
      quote: `“I am passionate about working with tech companies as they grow, innovate, and ultimately improve the way we live our lives.”`,
      video_url: 'assets/videos/19021_IP_SM_Final.mp4'
    }
  ];

  state = 'hide';
  offset = 0;

  constructor(public el: ElementRef, private globals: Globals) { }

  ngOnInit() {
  }

  @Input() vid2Obj: any;

  // Access the html5 video element via viewchild.
  @ViewChild('vid2') vid2: ElementRef;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    const modal = document.querySelector('#vid2modal');
    if (event.target === modal) {
      modal.classList.remove('is-active');
      modal.classList.remove('is-flex');
      this.vid2Obj.pause();
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    const modal = document.querySelector('#vid2modal');
    if (modal.classList.contains('is-active')) {
      modal.classList.remove('is-active');
      modal.classList.remove('is-flex');
      this.vid2Obj.pause();
    }
  }

  @HostListener('window:scroll', ['$event']) checkScroll() {
    const ne = this.el.nativeElement,
          direction = window.pageYOffset > this.offset ? 'down' : 'up';

    this.offset = window.pageYOffset;

    if (window.pageYOffset >= ne.offsetTop - ne.offsetHeight / 2 && window.pageYOffset <= ne.offsetTop + ne.offsetHeight) {
      if (direction === 'down') {
         this.state = 'show';
      } else {
        this.state = 'fadein';
      }
    } else {
      this.state = 'hide';
    }
  }

  scrollStart(el) {
    const ne = this.el.nativeElement,
      hr = el.element.querySelectorAll('hr'),
      svg = el.element.querySelectorAll('svg');

    if (window.pageYOffset < (ne.offsetTop - 100) || window.pageYOffset > (ne.offsetTop + ne.offsetHeight + 100)) {
      hr.forEach(h => {
        h.style.transition = 'none';
        h.style.opacity = '0';
      });
      svg.forEach(e => {
        e.style.transition = 'none';
        e.style.opacity = '0';
      });
    }
  }

  scrollDone(el) {
    const hr = el.element.querySelectorAll('hr'),
      svg = el.element.querySelectorAll('svg');

    setTimeout(() => {
      hr.forEach(h => {
        h.style.transition = 'all 500ms ease-in';
        h.style.opacity = '1';
      });
      setTimeout(() => {
        svg.forEach(s => {
          s.style.transition = 'all 600ms ease-out';
          s.style.opacity = '1';
        });
      }, 100);
    }, 250);
  }

  playVideo2(url, type, poster) {
    if (window.innerWidth < 768) {
      document.location.href = url;
    } else {
      const options = {
        controls: true,
        autoplay: false,
        preload: 'auto' ,
        techOrder: ['mp4', 'html5'],
        sources: [{ 'type': type, 'src': url }],
        poster: poster
      };

      if (this.vid2Obj) {
        this.vid2Obj.poster(poster);
        this.vid2Obj.src({type: type, src: url});
      } else {
        this.vid2Obj  =  new videojs(this.vid2.nativeElement, options, function onPlayerReady() {
        });
      }
      // document.getElementById('vid2-modal').style.display = 'inline-block';
      document.querySelector('#vid2modal').classList.add('is-active');
      document.querySelector('#vid2modal').classList.add('is-flex');
      this.vid2Obj.play();
    }
  }

  modalClose2() {
    document.querySelector('#vid2modal').classList.remove('is-active');
    document.querySelector('#vid2modal').classList.remove('is-flex');
    this.vid2Obj.pause();
  }
}
