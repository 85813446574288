<article class="main-section">
  <div class="parallax__wrap" [@scrollAnimation]="state" (@scrollAnimation.start)="scrollStart($event)" (@scrollAnimation.done)="scrollDone($event)">
      <section class="parallax__content--center">
        <div>
            <app-logo></app-logo>
            <div class="stripe-left">
                <h1 class="is-disabled">Technology:<br />Navigating the Future</h1>
                <h2 class="is-disabled">Technology companies are driving digital disruption, accelerating rapid organizational change, and creating new, more complex exposures. Combined with globalization and heightened regulatory pressures, innovative technology companies like yours are redefining the way in which the world transacts commerce.</h2>
                <div class="is-disabled featured-asset learn-more">
                  <div class="featured-asset--left">
                    <h2 class="is-disabled">Technology Industry Outlook</h2>
                    <p class="is-disabled">This special report takes a look at the state of the technology industry and key challenges top of mind for technology executives.</p>
                  </div>
                  <div class="featured-asset--right">
                    <a href="https://www.chubb.com/us-en/_assets/doc/t3-techmarketdynamics-q2.pdf" target="_blank" data-cta="Learn more" class="btn btn--orange is-disabled">Learn more
                      <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" style="margin-left:0.5rem;" viewBox="0 0 10.766 18.703"><defs><style>.a{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:2px;}</style></defs><path class="a" d="M0,0,8.645,8.645,0,17.289" transform="translate(0.707 0.707)"/></svg>
                    </a>
                  </div>
                </div>
              </div>
        </div>

      </section>
  </div>
</article>
