import { Component, HostListener, ElementRef, OnInit } from '@angular/core';
import { scrollToPage } from '../app.animations';
import {Globals} from '../globals';

@Component({
  selector: 'app-learn',
  templateUrl: './learn.component.html',
  styleUrls: ['./learn.component.scss'],
  animations: [scrollToPage]
})
export class LearnComponent implements OnInit {
  state = 'hide';
  offset = 0;

  reports = [
    {
      img: {
        src: 'assets/img/learn-pdf-1@2x.png',
        alt: `Benchmarking report thumbnail photo`
      },
      title: `Benchmarking report`,
      href: `assets/pdf/Chubb_PacTech_Benchmarking.pdf`,
      description: `See what insurance products your peers are buying. Also, get a glimpse into the claims we are seeing in the technology space, and how you can mitigate against those risks. View the report and ask your agent if you are adequately protected.`,
      share: {
        email_id: '6_2',
        web_root: 'https://www.chubb.com/us-en/campaign/commercial/',
        url: 'assets/pdf/Chubb_PacTech_Benchmarking.pdf',
        social_title: 'Chubb’s benchmarking report',
        social_linkedin: 'See what insurance products your peers are buying.',
        social_body: 'See what insurance products your peers are buying.',
        email_subject: 'Chubb’s benchmarking report',
        email_body: 'See what insurance products your peers are buying.',
        color: ''
      }
    },
    {
      img: {
        src: 'assets/img/learn-pdf-2@2x.png',
        alt: `Technology Industry Practice Overview thumbnail photo`
      },
      title: `Technology Industry Practice Overview`,
      href: `/_global-assets/documents/t2-technology-12-18.pdf`,
      description: `Learn more about our technology expertise, loss control, and claims services. Review this with your agent and ask for a Chubb quote.`,
      share: {
        email_id: '6_3',
        web_root: 'https://www.chubb.com/us-en/campaign/commercial/',
        url: '/_global-assets/documents/t2-technology-12-18.pdf',
        social_title: 'Chubb’s Technology Industry Expertise',
        social_linkedin: 'Check out how Chubb is well-positioned to proactively help technology businesses face an ever-expanding array of complex exposures.',
        social_body: 'Check out how Chubb is well-positioned to proactively help technology businesses face an ever-expanding array of complex exposures.',
        email_subject: 'Chubb’s Technology Industry Expertise',
        email_body: 'Check out how Chubb is well-positioned to proactively help technology businesses face an ever-expanding array of complex exposures.',
        color: ''
      }
    }

  ];

  constructor(public el: ElementRef, private globals: Globals) {}

  ngOnInit() {}

  @HostListener('window:scroll', ['$event']) checkScroll() {
    const ne = this.el.nativeElement,
          direction = window.pageYOffset > this.offset ? 'down' : 'up';

    this.offset = window.pageYOffset;

    if (window.pageYOffset >= ne.offsetTop - ne.offsetHeight / 2 && window.pageYOffset <= ne.offsetTop + ne.offsetHeight) {
      if (direction === 'down') {
        // this.state = 'show';
        this.state = 'fadein';
      } else {
        this.state = 'fadein';
      }
    } else {
      this.state = 'hide';
    }
  }

  scrollStart(el) {
    const ne = this.el.nativeElement,
      hr = el.element.querySelectorAll('hr'),
      svg = el.element.querySelectorAll('svg');

    if (window.pageYOffset < (ne.offsetTop - 100) || window.pageYOffset > (ne.offsetTop + ne.offsetHeight + 100)) {
      hr.forEach(h => {
        h.style.transition = 'none';
        h.style.opacity = '0';
      });
      svg.forEach(s => {
        s.style.transition = 'none';
        s.style.opacity = '0';
      });
    }
  }

  scrollDone(el) {
    const hr = el.element.querySelectorAll('hr'),
        svg = el.element.querySelectorAll('svg, img');

    setTimeout(() => {
      hr.forEach(h => {
        h.style.transition = 'all 500ms ease-in';
        h.style.opacity = '1';
      });
      setTimeout(() => {
        svg.forEach(s => {
          s.style.transition = 'all 600ms ease-out';
          s.style.opacity = '1';
        });
      }, 100);
    }, 250);
  }
}
