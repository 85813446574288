import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-btn-download',
  templateUrl: './btn-download.component.html',
  styleUrls: ['./btn-download.component.scss']
})
export class BtnDownloadComponent implements OnInit {

  @Input() href: any;
  @Input() title: any;

  constructor() { }

  ngOnInit() {
  }

}
