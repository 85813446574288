<div class="spotlight-card">
  <h3>{{ model.header }}</h3>

  <hr />

  <div class="flex-row">
    <div class="stat" *ngFor="let stat of model.stats">
      <div class="percentage">{{ stat.percentage }}</div>
      <div class="text" [innerHTML]="stat.text"></div>
    </div>
  </div>

  <div class="more-toggle more-toggle--open" *ngIf="!expand" (click)="expand = !expand">Dig in to learn more</div>
  <div class="more-toggle more-toggle--close" *ngIf="expand" (click)="expand = !expand">Close</div>
  <div class="more" *ngIf="expand">{{ model.more }}</div>
</div>
