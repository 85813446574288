<section class="parallax__content claims-promise clearfix">
  <h2>Honoring our claims promise</h2>
  <owl-carousel [options]="{ items: 1, dots: true, nav: true, onChange: this.owlChanged, loop: true }" [carouselClasses]="['owl-theme', 'sliding', 'clearfix']">
      <div class="item" *ngFor="let panel of panels">
        <div class="r-table">

            <div class="r-col r-col--bg" [ngStyle]="{'background-image':'url('+ panel.background +')'}">
                <a *ngIf="panel.video_url" (click)="playVideo(panel.video_url, 'video/youtube', panel.background);" attr.data-video-title="{{ panel.title }}" title="Watch the video" class="videoPlayIconTemplate para-blue">
                  <svg class="play-icon" xmlns="http://www.w3.org/2000/svg" viewBox="-7164.677 317 17.068 17.068">
                    <g id="Path_2174" data-name="Path 2174" class="play-icon-1" transform="translate(-7153.452 319.693) rotate(45)">
                      <path class="play-icon-2" d="M-3.809,3.178,8.262,0,5.08,12.067Z"/>
                      <path class="play-icon-3" d="M 4.556581020355225 10.12926578521729 L 6.857667922973633 1.403813600540161 L -1.870922088623047 3.701776504516602 L 4.556581020355225 10.12926578521729 M 5.079821586608887 12.06672286987305 L -3.808988571166992 3.177932977676392 L 8.26207160949707 2.865448095690226e-06 L 5.079821586608887 12.06672286987305 Z"/>
                    </g>
                  </svg>
                </a>
              </div>

              <div class="r-col">
                <div class="r-col--content">
                  <h3>{{ panel.title }}</h3>
                  <p *ngFor="let desc of panel.description">{{ desc }}</p>
                  <app-btn-watch *ngIf="panel.video_url" title="Watch the video" (click)="playVideo(panel.video_url, 'video/youtube', panel.background);"></app-btn-watch>
                </div>
              </div>


        </div>
      </div>
    </owl-carousel>
</section>

<div *ngFor="let panel of panels">
  <div *ngIf="panel.video_url" id="modal" class="modal vid-modal">
    <div class="modal__inner">
        <span class="modal__close" (click)="modalClose();">&times;</span>
        <video #vid id="vid" class="video-js vjs-default-skin vjs-16-9 vjs-big-play-centered" [poster]="panel.background" >
            <source [src]="panel.video_url" type="video/youtube" />
        </video>
    </div>
  </div>
</div>
