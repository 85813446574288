import { Component, OnInit, HostListener, ViewChild, ElementRef, Input } from '@angular/core';

declare let videojs: any ;

@Component({
  selector: 'app-claims-promise',
  templateUrl: './claims-promise.component.html',
  styleUrls: ['./claims-promise.component.scss']
})
export class ClaimsPromiseComponent implements OnInit {

  panels = [
    {
      background: `assets/img/Vermeer@2x.jpg`,
      title: 'The Vermeer Story',
      description: [
        `Hear how a business client overcame the most severe tornado to hit Iowa in three years with our help.`
      ],
      video_url: 'https://www.youtube.com/watch?v=n9He9rNfXko'
    },
    {
      background: `assets/img/expertise-1@2x.png`,
      title: 'Minimizing loss',
      description: [
        `Our product operated using lithium ion batteries, which we obtained from a third-party supplier and incorporated into a battery pack. Our customer suffered injury due to fires originating from the battery packs. The fires were caused by a defect in the batteries manufactured by the third-party supplier and claims were filed for injury due to the fires.`,
        `As a result, we incurred significant expense in the withdrawal of the faulty product to avoid further injury from the fires. Luckily, we had a Chubb policy in place to minimize the losses associated with the faulty batteries.`
      ]
    },
    {
      background: `assets/img/expertise-2@2x.png`,
      title: 'Multinational coverage',
      description: [
        `When the first floor of our South Korea-based processing plant caught fire, the flames spread quickly, causing the roof to collapse. The building was a total loss. We exhausted our local policy’s property damage limits and didn’t have business interruption on that policy. But thankfully, we had Chubb’s multinational program in place.`,
        `The multinational program allowed us to replace the damaged property, provided income replacement during the period required to do so, and allowed us to get our business back up to speed.`
      ]
    }

  ];

  @Input() vidObj: any;

  // Access the html5 video element via viewchild.
  @ViewChild('vid') vid: ElementRef;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    const modal = document.querySelector('#modal');
    if (event.target === modal) {
      modal.classList.remove('is-active');
      modal.classList.remove('is-flex');
      this.vidObj.pause();
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    const modal = document.querySelector('#modal');
    if (modal.classList.contains('is-active')) {
      modal.classList.remove('is-active');
      modal.classList.remove('is-flex');
      this.vidObj.pause();
    }
  }

  constructor() { }

  ngOnInit() {
  }

  playVideo(url, type, poster) {
    if (window.innerWidth < 768) {
      document.location.href = url;
    } else {
      const options = {
        controls: true,
        autoplay: false,
        preload: 'auto' ,
        techOrder: ['youtube', 'html5'],
        sources: [{ 'type': type, 'src': url }],
        poster: poster
      };

      if (this.vidObj) {
        this.vidObj.poster(poster);
        this.vidObj.src({type: type, src: url});
      } else {
        this.vidObj  =  new videojs(this.vid.nativeElement, options, function onPlayerReady() {
          // videojs.log('Your player is ready!', this);
        });
      }
      // document.getElementById('modal').style.display = 'flex';
      document.querySelector('#modal').classList.add('is-active');
      document.querySelector('#modal').classList.add('is-flex');
      this.vidObj.play();
    }
  }

  modalClose() {
    document.querySelector('#modal').classList.remove('is-active');
    document.querySelector('#modal').classList.remove('is-flex');
    this.vidObj.pause();
  }

  owlChanged(e) {
    if (e.item.index > 0) {
      window.dataLayer.push({
        'event': 'carouselEvent',
        'eventCategory': 'Carousel Click',
        'eventAction': 'Clicked on carousel control',
        'eventLabel': 'Slide ' + e.item.index
      });
    }
  }

}
