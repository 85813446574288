export class ContactForm {

  constructor(
    public i_am: string,
    public name_first: string,
    public name_last: string,
    public email: string,
    public zip: string,
    public nob: string,
    public other?: string,
    public phone?: string,
    public comment?: string
  ) {  }

}
