<section id="calculator" class="calculator clearfix calculator-fix">
    <div class="calculator--container">
        <div class="calculator--content clearfix">
          <div class="block left">

          </div>
          <div class="block">
            <div class="block--content">
              <h2>Chubb Cyber Index<sup>SM</sup></h2>
              <p>
                The Chubb Cyber Index<sup>SM</sup> compiles our proprietary claims data to report the prevailing cyber threats and the historical trends relevant to your business.
              </p>
              <div class="btn-grp">
                <a href="https://www.chubbcyberindex.com/" class="btn" target="_new" data-cta="Explore the current data" title="Explore the current data (opens in a new window)">Explore the current data
                  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" style="margin-left:0.5rem;" viewBox="0 0 10.766 18.703"><defs><style>.a{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:2px;}</style></defs><path class="a" d="M0,0,8.645,8.645,0,17.289" transform="translate(0.707 0.707)"/></svg>
                </a>
              </div>
            </div>
          </div>
        </div>
    </div>
</section>
