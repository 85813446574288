import { Component, HostListener, ElementRef, OnInit } from '@angular/core';
import { scrollToPage } from '../app.animations';
import {Globals} from '../globals';

@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.scss'],
  animations: [scrollToPage]
})
export class OpportunityComponent implements OnInit {
  state = 'hide';
  offset = 0;

  spotlights = [
    {
      header: 'Cyber security',
      stats: [
        { percentage: '52%', text: 'Tech' },
        { percentage: '47%', text: 'Middle<br />Market' }
      ],
      more: 'The physical and digital worlds are increasingly connected, leading to disruptive cyberattacks that may impact the delivery of products and services to customers. Working with an insurance provider that offers pre- and post-breach cyber services is crucial to mitigating cyber risk, which is as much about service as it is about traditional insurance risk transfer. Pairing industry-leading cyber services with robust and integrated insurance solutions helps protect your technology company’s balance sheet and enables you to respond to claims from customers and other third parties.'
    },
    {
      header: 'Competitive risk—new or disruptive competitors',
      stats: [
        { percentage: '42%', text: 'Tech' },
        { percentage: '38%', text: 'Middle<br />Market' }
      ],
      more: 'Technology companies like yours are faced with fierce competition for your products, services, talent, and intellectual property. Downtime caused by business disruption can be devastating to your reputation, and bottom line. Therefore, getting your business back up and running is critical. In addition to traditional insurance products, today’s technology companies need cutting-edge insurance solutions that enable them to not only protect their assets but also their reputation and goodwill. Insurance solutions such as Product Recall, Crisis Management Expense, and Privacy Remediation Expense help technology companies minimize adverse publicity and mitigate harm to their marketplace reputation. An integrated insurance program should include these types of solutions to protect your company globally.'
    },
    {
      header: 'Legal, regulatory, or compliance',
      stats: [
        { percentage: '41%', text: 'Tech' },
        { percentage: '38%', text: 'Middle<br />Market' }
      ],
      more: 'Technology companies like yours are increasingly exposed to various legal and regulatory risks such as protection of confidential customer information and intellectual property, adherence to consumer protection and environmental laws, and compliance with evolving construction standards.'
    },
    {
      header: 'Supply chain risk',
      stats: [
        { percentage: '33%', text: 'Tech' },
        { percentage: '27%', text: 'Middle<br />Market' }
      ],
      more: 'Increased digitization has led to new supply chain risks arising from cyber events that impact third-party technology and service vendors. Your company may be exposed when third-party contract manufacturers, service providers, and other links in your supply chain suffer physical loss and disruption of operations. As such, you need an insurance provider that has a global reach and the experience to address exposures around the world with a seamless portfolio of insurance products and services that address losses wherever they may occur.'
    }
  ];

  panels = [
    {
      title: 'Technology industry outlook',
      href: 'https://www.chubb.com/us-en/_assets/doc/t3-techmarketdynamics-q2.pdf',
      image: {
        src: 'assets/img/spotlight-outlook@2x.jpg'
      },
      share: {
        email_id: 'email_sp1',
        url: 'https://www.chubb.com/us-en/_assets/doc/t3-techmarketdynamics-q2.pdf',
        social_title: 'Technology Industry Outlook from Chubb',
        social_linkedin: 'Check out this special report from Chubb that takes a look at the state of the technology industry and key challenges top of mind for technology executives.',
        social_body: 'Check out this special report from Chubb that takes a look at the state of the technology industry and key challenges top of mind for technology executives.',
        email_subject: 'Technology Industry Outlook from Chubb',
        email_body: 'Check out this special report from Chubb that takes a look at the state of the technology industry and key challenges top of mind for technology executives.',
        color: 'orange'
      }
    },
    {
      title: 'Take our survey',
      href: '',
      image: {
        src: 'assets/img/spotlight-survey@2x.jpg'
      }
    }
  ];

  constructor(public el: ElementRef, private globals: Globals) { }

  ngOnInit() {
  }

  @HostListener('window:scroll', ['$event']) checkScroll() {
    const ne = this.el.nativeElement,
          direction = window.pageYOffset > this.offset ? 'down' : 'up';

    this.offset = window.pageYOffset;

    if (window.pageYOffset >= ne.offsetTop - ne.offsetHeight / 2 && window.pageYOffset <= ne.offsetTop + ne.offsetHeight) {
      if (direction === 'down') {
        this.state = 'show';
      } else {
        this.state = 'fadein';
      }
    } else {
      this.state = 'hide';
    }
  }

  scrollStart(el) {
    const ne = this.el.nativeElement,
          hr = el.element.querySelectorAll('hr'),
          svg = el.element.querySelectorAll('svg');

    if (window.pageYOffset < (ne.offsetTop - 100) || window.pageYOffset > (ne.offsetTop + ne.offsetHeight + 100)) {
      hr.forEach(h => {
        h.style.transition = 'none';
        h.style.opacity = '0';
      });
      svg.forEach(s => {
        s.style.transition = 'none';
        s.style.opacity = '0';
      });
    }
  }

  scrollDone(el) {
    const hr = el.element.querySelectorAll('hr'),
        svg = el.element.querySelectorAll('svg');

    setTimeout(() => {
      hr.forEach(h => {
        h.style.transition = 'all 500ms ease-in';
        h.style.opacity = '1';
      });
    }, 50);

    svg.forEach(s => {
      s.style.transition = 'all 1000ms ease-out';
      s.style.opacity = '1';
    });
  }

}
