<article>
  <div class="parallax__wrap" [@scrollAnimation]="state" (@scrollAnimation.start)="scrollStart($event)" (@scrollAnimation.done)="scrollDone($event)">
    <section class="parallax__content">
      <h1 class="is-disabled">The state of the tech industry</h1>
      <hr />

      <div class="clearfix">
        <div class="outlook--intro">
          <h3 class="is-disabled">In a recent survey, technology executives like you reported higher revenue and employment growth compared to their non-tech middle market peers. However, they also reported lower economic confidence and are less likely to invest an extra dollar vs the same cohort.</h3>
        </div>

      </div>



      <owl-carousel
        [options]="{ items: 1, dots: true, nav: true, onChange: this.owlChanged, loop: true }"
        [items]="outlookCards"
        [carouselClasses]="['owl-theme', 'sliding', 'outlook--owl', 'outlook__slider', 'clearfix']">
          <div class="item outlook__slide clearfix"
            *ngFor="let card of outlookCards">
              <h3
                [ngStyle]="{'background-image': 'url(assets/img/svg/'+ card.direction +'.svg)'}"
              >{{ card.header }}</h3>
              <div class="vs">
                <div class="left">
                  <div class="percent">
                    {{ card.left.stat}}%
                  </div>
                  <p class="text" [innerHTML]="card.left.text"></p>
                </div>
                <div class="center">VS</div>
                <div class="right">
                  <div class="percent">
                    {{ card.right.stat}}%
                  </div>
                  <p class="text">
                    {{ card.right.text }}
                  </p>
                </div>
              </div>
          </div>
      </owl-carousel>


      <div class="clearfix" style="padding-top: 2rem;">
        <h3 class="is-disabled">Business investment may be lower due to perceptions of increasing risk:</h3>

        <div class="outlook__panels">
          <div class="outlook__panels--panel">
            <div class="percent">45%</div>
            <div class="text">believe risk in the <b>tech industry</b> will increase slightly/dramatically</div>
          </div>
          <div class="outlook__panels--gutter"></div>
          <div class="outlook__panels--panel">
            <div class="percent">52%</div>
            <div class="text">believe risk for their <b>tech company</b> will increase slightly/dramatically</div>
          </div>
        </div>
      </div>

      <br clear="all" />
      <div class="outlook__disclaim">
        Source: National Center for the Middle Market - Q2 2019 Middle Market Indicator
      </div>
    </section>
  </div>
</article>

