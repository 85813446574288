<article>
  <div class="parallax__wrap" [@scrollAnimation]="state" (@scrollAnimation.start)="scrollStart($event)" (@scrollAnimation.done)="scrollDone($event)">
    <section class="parallax__content">

        <h1 class="is-disabled">Most challenging risks for tech businesses</h1>
        <hr />
        <h3 class="percent66 is-disabled">Technology businesses like yours are placing a high priority on risk management with nearly two-thirds ranking it as critically important.</h3>
        <p class="percent66 is-disabled">In the survey, several key challenges were identified as extremely or very challenging to manage, each of which has an impact on risk management.</p>

        <br clear="all" />
      <ul data-slider="opportunity_1" class="opportunity__slider clearfix">
        <li class="opportunity__slide" *ngFor="let spotlight of spotlights">
          <app-spotlight [model]="spotlight"></app-spotlight>
        </li>
      </ul>



      <ul data-slider="opportunity_2" class="opportunity__slider clearfix">
        <li class="opportunity__alt" *ngFor="let panel of this.panels" >
          <figure [ngStyle]="{ 'background-image': 'url(' + panel.image.src + ')' }"></figure>
          <section class="opportunity__content">
            <h3>{{ panel.title }}</h3>
            <section class="chubb-card__btn-group clearfix">
              <app-btn-download *ngIf="panel.href" href="{{ panel.href }}" title="{{ panel.title }}"></app-btn-download>
              <div *ngIf="!panel.href" class="btn btn--disabled" disabled="true">Coming Soon</div>
              <app-btn-share *ngIf="panel.share"
                email_id="{{ panel.share.email_id }}"
                web_root="{{ panel.share.web_root }}"
                url="{{ panel.share.url }}"
                social_title="{{ panel.share.social_title }}"
                social_linkedin="{{ panel.share.social_linkedin }}"
                social_body="{{ panel.share.social_body }}"
                email_subject="{{ panel.share.email_subject }}"
                email_body="{{ panel.share.email_body }}"
                color="{{ panel.share.color }}"
            ></app-btn-share>
            </section>
          </section>
        </li>
      </ul>

      <h3 class="percent66 is-disabled">
        We understand the unique risks in your industry and have dedicated technology experts who can craft tailored solutions for technology businesses like yours.
      </h3>
      <br clear="all" />
      <div class="opportunity__disclaim clearfix">Source: National Center for the Middle Market - Q2 2019 Middle Market Indicator</div>
    </section>
  </div>
</article>
