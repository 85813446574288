import { Component, OnInit, ViewChild } from '@angular/core';
import { formComplete } from '../app.animations';
import { ContactForm } from '../models/contact-form';
import { NgForm } from '@angular/forms';
import { WorkService } from '../services/work.service';


@Component({
  selector: 'app-work-form',
  templateUrl: './work-form.component.html',
  styleUrls: ['./work-form.component.scss'],
  animations: [formComplete]
})
export class WorkFormComponent implements OnInit {

  formModel = new ContactForm('', '', '', '', '', '');
  @ViewChild('workForm') workForm: NgForm;

  iAmOptions = [
    {
      title: 'Please Select',
      value: '',
      description: ''
    },
    {
      title: 'Agent/Broker',
      value: 'Agent/Broker',
      description: `I would like to learn more about your resources for my tech clients and the type of services and solutions you can provide.
OR
I would like to learn more about getting a Chubb appointment.`
    },
    {
      title: 'Current client',
      value: 'Current client',
      description: `Please help out with a question related to my current account with Chubb.
OR
I would like to access more Chubb resources for tech businesses.`
    },
    {
      title: 'Prospective client',
      value: 'Prospective client',
      description: `I would like to be connected with an insurance professional who can discuss my company’s risks.`
    },
    {
      title: 'Other',
      value: 'Other',
      description: ``
    }
  ];
  iAmSelected = '';
  showOther = false;
  submitted = false;
  response = false;
  myRecaptcha: boolean;
  pageUrl = window.location.href;

  onScriptLoad() {
      // console.log('Google reCAPTCHA loaded and is ready for use!')
  }

  onScriptError() {
      // console.log('Something went wrong when loading the Google reCAPTCHA')
  }

  constructor(private workService: WorkService) { }

  ngOnInit() {

  }

  onSubmit() {
    window.dataLayer.push({
      'event': 'formStep',
      'formName': 'Connect with a Local Rep',
      'formStepName': 'Submit',
      'formStepNumber': 1,
      'formComplete': true,
    });
    const apiData = {};
    const formData = [];
    Object.keys(this.workForm.form.controls).map(key => {
      const formField = {};
      formField['Name'] = key;
      formField['IsEmail'] = (key === 'Email Address');
      if (key === 'Timestamp') {
        formField['Value'] = new Date().toString();
      } else if (key === 'MID') {
        formField['Value'] = '10929662';
      } else if (key === 'WebComment') {
        formField['Value'] = this.workForm.form.controls[key].value ?  this.workForm.form.controls[key].value.replace(/\n/g, ' ') : '';
      } else  {
        formField['Value'] = this.workForm.form.controls[key].value || '';
      }
      formField['Placeholder'] = key;
      formData.push(formField);
    });

    apiData['jsonData'] = JSON.stringify({'Data': formData});
    apiData['ExternalKey'] = 'PacTechLandingPageTrigger';
    apiData['grecaptcharesponse'] = (<any>window).grecaptcha.getResponse();
    apiData['ETAccId'] = '10929662';

    this.workService.saveDetails(apiData)
    .pipe()
    .subscribe(
      data => {
        this.submitted = true;
        this.response = (data.d === 'OK');
      },
      error => {
        this.submitted = true;
        this.response = false;
      }
    );

  }

  iAmHandler(event: any) {
    this.iAmSelected = event.target.value;
    const res = this.iAmOptions.find(obj => {
      return obj.title === this.iAmSelected;
    });
    this.showOther = (this.iAmSelected === 'Other') ? true : false;
    this.formModel.comment = res.description;

    window.dataLayer.push({
      event: 'formEvent',
      formName: 'Connect with a Local Rep',
      formStepName: 'Agent ID',
      formEventName: 'Select: ' + this.iAmSelected
    });
  }

  dlHandler(event: any) {
    window.dataLayer.push({
      event: 'formEvent',
      formName: 'Connect with a Local Rep',
      formStepName: event.target.name,
      formEventName: 'Field Changed'
    });
  }
}
