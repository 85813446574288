import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-share',
  templateUrl: './btn-share.component.html',
  styleUrls: ['./btn-share.component.scss']
})
export class BtnShareComponent implements OnInit {

  @Input() email_id: string;
  @Input() web_root: string;
  @Input() url: string;
  @Input() social_title: string;
  @Input() social_body: string;
  @Input() social_linkedin: string;
  @Input() email_subject: string;
  @Input() email_body: string;
  @Input() color: string;

  constructor() { }

  ngOnInit() {
  }

}
