<article>
  <div class="parallax__wrap" [@scrollAnimation]="state" (@scrollAnimation.start)="scrollStart($event)" (@scrollAnimation.done)="scrollDone($event)">
    <section class="work parallax__content">
      <h1 class="is-disabled">We’re here to help</h1>
      <hr class="bpad" />

      <owl-carousel [options]="slideOptions" [carouselClasses]="['owl-theme', 'sliding', 'work--owl', 'clearfix']" >
        <div class="item" *ngFor="let person of people">
         <div class="person-card" [ngStyle]="{'background-image':'url('+ person.photo.src +')'}">

          <a *ngIf="person.video_url" (click)="playVideo2(person.video_url, 'video/mp4', person.photo.src);" attr.data-video-title="{{ person.name }}" title="Watch the video" class="videoPlayIconTemplate para-blue">
            <svg class="play-icon" xmlns="http://www.w3.org/2000/svg" viewBox="-7164.677 317 17.068 17.068">
              <g id="Path_2174" data-name="Path 2174" class="play-icon-1" transform="translate(-7153.452 319.693) rotate(45)">
                <path class="play-icon-2" d="M-3.809,3.178,8.262,0,5.08,12.067Z"/>
                <path class="play-icon-3" d="M 4.556581020355225 10.12926578521729 L 6.857667922973633 1.403813600540161 L -1.870922088623047 3.701776504516602 L 4.556581020355225 10.12926578521729 M 5.079821586608887 12.06672286987305 L -3.808988571166992 3.177932977676392 L 8.26207160949707 2.865448095690226e-06 L 5.079821586608887 12.06672286987305 Z"/>
              </g>
            </svg>
          </a>

           <div class="content">
              <h4>{{ person.header }}</h4>
              <h3>{{ person.name }}</h3>
              <hr />
              <div class="clearfix">
                <ul class="points clearfix" *ngFor="let point of person.points">
                  <li class="point" [innerHTML]="point"></li>
                </ul>
              </div>
              <p class="quote">{{ person.quote }}</p>
           </div>

         </div>
        </div>
       </owl-carousel>


      <a name="contact"></a>
      <app-work-form></app-work-form>


      <app-find-agent></app-find-agent>

    </section>

  </div>
</article>

<div *ngFor="let person of people">
  <div *ngIf="person.video_url" id="vid2modal" class="modal">
    <div class="modal__inner">
        <span class="modal__close" (click)="modalClose2();">&times;</span>
        <video #vid2 id="vid2" class="video-js vjs-default-skin vjs-16-9 vjs-big-play-centered" >
            <source [src]="person.video_url" type="video/mp4" />
        </video>
    </div>
  </div>
</div>
