import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { scrollToPage } from '../app.animations';
import {Globals} from '../globals';

@Component({
    selector: 'app-outlook',
    templateUrl: './outlook.component.html',
    styleUrls: ['./outlook.component.scss'],
    animations: [scrollToPage]
})
export class OutlookComponent implements OnInit {

    outlookCards = [
      {
        direction: 'increase',
        header: 'Revenue Growth | Past 12 months',
        left: {
            stat: '11.3',
            text: 'Tech'
        },
        right: {
          stat: '8.5',
          text: 'U.S. Middle Market'
        }
      },
      {
        direction: 'increase',
        header: 'Employment Growth | Past 12 months',
        left: {
            stat: '8.3',
            text: 'Tech'
        },
        right: {
          stat: '6.4',
          text: 'U.S. Middle Market'
        }
      },
      {
        direction: 'decrease',
        header: 'Economic Confidence | Q2 2019',
        left: {
            stat: '81',
            text: 'Tech | <i>U.S. Only</i>'
        },
        right: {
          stat: '82',
          text: 'U.S. Middle Market'
        }
      },
      {
        direction: 'decrease',
        header: 'Investment | % saying they would invest an extra dollar vs. putting it aside',
        left: {
            stat: '40',
            text: 'Tech'
        },
        right: {
          stat: '65',
          text: 'U.S. Middle Market'
        }
      }
    ];

    panels = [
        {
            title: `4Q 2018 Middle market indicator`,
            href: `assets/pdf/NCMM_MMI_Q4_2018.pdf`,
            image: {
                src: `assets/img/outlook-logo-ncmm@2x.png`
            },
            share: {
                email_id: '2_1',
                web_root: 'https://www.chubb.com/pacific-tech/',
                url: 'assets/pdf/NCMM_MMI_Q4_2018.pdf',
                social_title: 'Check out the NCMM Middle Market Indicator',
                social_linkedin: 'Check out a special report, the NCMM quarterly middle market indicator.',
                social_body: 'Check out a special report, the NCMM quarterly middle market indicator.',
                email_subject: 'Check out the NCMM Middle Market Indicator',
                email_body: 'You might be interested in this resource from Chubb and the National Center for the Middle Market. Take a look.',
                color: ''
            }
        }
    ];
    // panelOptions = { items: 1, dots: true, nav: true };
    state = 'hide';
    direction = 'down';
    offset = 0;

    constructor(public el: ElementRef, protected sanitizer: DomSanitizer, private globals: Globals) { }

    @HostListener('window:scroll', ['$event']) checkScroll() {
        const ne = this.el.nativeElement;

        this.direction = window.pageYOffset > this.offset ? 'down' : 'up';
        this.offset = window.pageYOffset;

        if (window.pageYOffset >= ne.offsetTop - ne.offsetHeight / 2 && window.pageYOffset <= ne.offsetTop + ne.offsetHeight) {
          if (this.direction === 'down') {
            this.state = 'show';
          } else {
            this.state = 'fadein';
          }
        } else {
          this.state = 'hide';
        }
    }

    ngOnInit() {
    }

    scrollStart(el) {
      const ne = this.el.nativeElement,
        hr = el.element.querySelectorAll('hr'),
        svg = el.element.querySelectorAll('svg');
        if (window.pageYOffset < (ne.offsetTop - 100) || window.pageYOffset > (ne.offsetTop + ne.offsetHeight + 100)) {
            hr.forEach(h => {
                h.style.transition = 'none';
                h.style.opacity = '0';
            });
            svg.forEach(s => {
                s.style.transition = 'none';
                s.style.opacity = '0';
            });
        }
    }

    scrollDone(el) {
        const hr = el.element.querySelectorAll('hr'),
          svg = el.element.querySelectorAll('svg, img');

        setTimeout(() => {
            hr.forEach(h => {
                h.style.transition = 'all 500ms ease-in';
                h.style.opacity = '1';
            });
            setTimeout(() => {
                svg.forEach(s => {
                  s.style.transition = 'all 600ms ease-out';
                  s.style.opacity = '1';
                });
            }, 500);
        }, 250);
    }

    owlChanged(e) {
      console.log('e', e);
      if (e.item.index > 0) {
        window.dataLayer.push({
          'event': 'carouselEvent',
          'eventCategory': 'Carousel Click',
          'eventAction': 'Clicked on carousel control',
          'eventLabel': 'Slide ' + e.item.index
        });
      }
    }
}
