import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

  indexfile = '';

  constructor() { }

  ngOnInit() {
    this.indexfile = (location.href.includes('rediscover-technology.aspx')) ? 'rediscover-technology.aspx' : '';
  }

}
