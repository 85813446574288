import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { scrollToPage } from '../app.animations';
import {Globals} from '../globals';
import { NavigationNextComponent } from '../navigation-next/navigation-next.component';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
  animations: [scrollToPage]
})
export class IntroComponent implements OnInit {
  state = 'hide';
  offset = 0;
  nn = NavigationNextComponent.prototype;

  constructor(public el: ElementRef, private globals: Globals) {}

  @HostListener('window:scroll', ['$event']) checkScroll() {
    const ne = this.el.nativeElement,
          direction = window.pageYOffset > this.offset ? 'down' : 'up';

    this.offset = window.pageYOffset;

    if (window.pageYOffset >= ne.offsetTop - ne.offsetHeight / 2 && window.pageYOffset <= ne.offsetTop + ne.offsetHeight) {
      if (direction === 'down') {
        this.state = 'show';
      } else {
        this.state = 'fadein';
      }
    } else {
      this.state = 'hide';
    }
  }

  ngOnInit() {
    this.state = 'show';
  }

  scrollStart(el) {
    const ne = this.el.nativeElement,
      svg = el.element.querySelectorAll('svg, img, figure');

    if (window.pageYOffset < (ne.offsetTop - 100) || window.pageYOffset > (ne.offsetTop + ne.offsetHeight + 100)) {
      svg.forEach(e => {
        e.style.transition = 'none';
        e.style.opacity = '0';
      });
    }
  }

  scrollDone(el) {
    const sl = el.element.querySelector('.stripe-left'),
      svg = el.element.querySelectorAll('svg, img, figure');

    setTimeout(() => {
      sl.style.transition = 'all 1000ms ease-in-out';
      sl.style.opacity = '1';
    }, 1700);

    setTimeout(() => {
      svg.forEach(s => {
        s.style.transition = 'all 800ms ease-out';
        s.style.opacity = '1';
      });
    }, 1500);

  }
}
