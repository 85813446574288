<article>
  <div class="parallax__wrap" [@scrollAnimation]="state" (@scrollAnimation.start)="scrollStart($event)" (@scrollAnimation.done)="scrollDone($event)">
    <section class="parallax__content">
      <h1 class="is-disabled">We know technology</h1>
      <hr />
      <h3 class="percent66 is-disabled">We put our specialized knowledge to work for you, with products and services tailored to address the unique needs of technology businesses like yours.</h3>

      <br clear="all" />
      <div class="block checklist-block clearfix">
        <ul class="checklist-block--list list-unstyled row">
          <li class="checklist-block--list-item clearfix">
              <div class="clearfix">
                  <img src="assets/img/li-1@2x.png" width="30" height="31" alt="1">
                  <p class="checklist-block__copy clearfix">Eliminate unforeseen gaps in insurance with our seamlessly integrated products</p>
              </div>
          </li>

          <li class="checklist-block--list-item clearfix">
              <div class="clearfix">
                  <img src="assets/img/li-2@2x.png" width="30" height="31" alt="2">
                  <p class="checklist-block__copy clearfix">Tailor your protection to address risks you are most likely to face</p>
              </div>
          </li>

          <li class="checklist-block--list-item clearfix">
              <div class="clearfix">
                  <img src="assets/img/li-3@2x.png" width="30" height="31" alt="3">
                  <p class="checklist-block__copy clearfix">Anticipate and address problems before they happen</p>
              </div>
          </li>

          <li class="checklist-block--list-item clearfix">
            <div class="clearfix">
                <img src="assets/img/li-4@2x.png" width="30" height="31" alt="4">
                <p class="checklist-block__copy clearfix">Assess your risk with help from our technology risk engineering experts</p>
            </div>
          </li>
        </ul>
        <ul class="checklist-block--list list-unstyled row">

          <li class="checklist-block--list-item clearfix">
            <div class="clearfix">
                <img src="assets/img/li-5@2x.png" width="30" height="31" alt="5">
                <p class="checklist-block__copy clearfix">Mitigate cyber breaches with our pre- and post-breach cyber services</p>
            </div>
          </li>

          <li class="checklist-block--list-item clearfix">
            <div class="clearfix">
                <img src="assets/img/li-6@2x.png" width="30" height="31" alt="6">
                <p class="checklist-block__copy clearfix">Protect your business overseas with resources globally</p>
            </div>
          </li>

          <li class="checklist-block--list-item clearfix">
            <div class="clearfix">
                <img src="assets/img/li-7@2x.png" width="30" height="31" alt="7">
                <p class="checklist-block__copy clearfix">Resolve covered claims fairly and quickly</p>
            </div>
          </li>
        </ul>
      </div>

      <h2>Real Companies. Real Protection.</h2>
      <hr class="gray" />
      <owl-carousel [options]="slideOptions" [carouselClasses]="['owl-theme', 'sliding', 'clearfix', 'owl-protection']" #owlElement>
        <div class="item" *ngFor="let slide of slides">
         <div style="text-align: center; max-width: 80%; margin: 0 auto;">
           <img src="{{ slide.icon.src }}" alt="{{ slide.icon.alt }}" width="82" style="max-width: 82px; margin: 1rem auto;" />
           <h3>{{ slide.title }}</h3>
           <ul class="points clearfix">
            <li *ngFor="let point of slide.points">
              {{ point }}
            </li>
           </ul>

           <p [innerHTML]="slide.description" class="clearfix" style="margin:0;"></p>
           <strong *ngIf="slide.difference">The Chubb difference:<br /></strong>
           <ul *ngIf="slide.difference">
             <li *ngFor="let dif of slide.difference">{{ dif }}</li>
           </ul>
           <div [innerHTML]="slide.footer" *ngIf="slide.footer"></div>
         </div>
        </div>
      </owl-carousel>
      <hr class="gray" />

    </section>

    <app-claims-promise></app-claims-promise>
  </div>
</article>
