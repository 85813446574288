

<section class="main " role="main">
  <section class="loader-wrap"></section>

  <!-- page components -->
  <section>
    <app-intro id="intro" class="parallax--block intro clearfix"></app-intro>
    <app-outlook id="outlook" class="parallax--block outlook clearfix"></app-outlook>
    <app-opportunity id="opportunity" class="parallax--block opportunity clearfix"></app-opportunity>
    <app-expertise id="expertise" class="parallax--block expertise clearfix"></app-expertise>
    <app-work id="work" class="parallax--block work clearfix"></app-work>
    <app-learn id="learn" class="bg-grey block parallax--block learn clearfix"></app-learn>
  </section>


  <!-- NAVIGATION -->
  <app-navigation-next></app-navigation-next>
  <app-navigation></app-navigation>

</section>
