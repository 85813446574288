/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./btn-watch.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./btn-watch.component";
var styles_BtnWatchComponent = [i0.styles];
var RenderType_BtnWatchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BtnWatchComponent, data: {} });
export { RenderType_BtnWatchComponent as RenderType_BtnWatchComponent };
export function View_BtnWatchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "btn"]], [[1, "data-video-title", 0], [1, "href", 4], [8, "title", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-play"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "btn"; var currVal_4 = _co.color; _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = _co.href; var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.title, ""); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _co.title; _ck(_v, 2, 0, currVal_5); }); }
export function View_BtnWatchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-btn-watch", [], null, null, null, View_BtnWatchComponent_0, RenderType_BtnWatchComponent)), i1.ɵdid(1, 114688, null, 0, i3.BtnWatchComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BtnWatchComponentNgFactory = i1.ɵccf("app-btn-watch", i3.BtnWatchComponent, View_BtnWatchComponent_Host_0, { href: "href", title: "title", color: "color" }, {}, []);
export { BtnWatchComponentNgFactory as BtnWatchComponentNgFactory };
