import { Component, HostListener, ElementRef, OnInit, ViewChild } from '@angular/core';
import { scrollToPage } from '../app.animations';
import {Globals} from '../globals';

@Component({
  selector: 'app-expertise',
  templateUrl: './expertise.component.html',
  styleUrls: ['./expertise.component.scss'],
  animations: [scrollToPage]
})
export class ExpertiseComponent implements OnInit {

  constructor(public el: ElementRef, private globals: Globals) {}
  slides = [
    {
      title: `B2B Cloud-based Software Provider`,
      icon: {
        src: `assets/img/expertise-cloud@2x.png`,
        alt: `Cloud icon`
      },
      points: [
        `Growing fast`,
        `Expanding operations overseas`,
        `Concerned about data privacy and security`
      ],
      description: `This fast-growing business requires tailored solutions that integrate property,
        casualty, and cyber insurance and can grow with them as they continue international
        expansion to enter new markets.  With operations in 54 countries and an ability to do
        business in over 200 countries, our seamless, global solutions are backed by a dedicated
        multinational claims unit that understands the unique situations that typically accompany a foreign loss or occurrence.`,
      // difference: [
      //   `Integrity+ by Chubb, including additive limits for first party and impairment of computer services`,
      //   `Ability to provide a seamless, global solution`
      // ]
    },
    {
      title: `Autonomous Systems Provider`,
      icon: {
        src: `assets/img/svg/AutonomousSystems.svg`,
        alt: `Systems icon`
      },
      points: [
        `Highly regulated`,
        `Innovative technology pioneer`,
        `Complex insurance requirements`
      ],
      description: `This client is continually innovating in a highly regulated industry and requires custom
        solutions to address existing and emerging risks. Our flexibility with structure and
        product allows them to focus on innovation. In addition, collaborating on risk mitigation
        is a top priority for this client, and our certified risk engineering specialists provide them
        with assistance from some of the best minds in the industry.`
    },
    {
      title: `Telecom Wi-Fi / Content Provider`,
      icon: {
        src: `assets/img/svg/TelecomWiFi.svg`,
        alt: `Telecom icon`
      },
      points: [
        `Multinational player`,
        `5,000 employees`,
        `Telecom research innovator`
      ],
      description: `This client provides digital products and services embedded in aircraft and marine
        vessels and requires state-of-the-art solutions for technology, aviation, and marine
        exposures. Our industry expertise allows us to understand their unique needs and tailor
        solutions that incorporate specialty products for marine and aviation exposures, all
        through one carrier.`
    },
    {
      title: `Technology Consultant Firm`,
      icon: {
        src: `assets/img/svg/techconsultingfirm.svg`,
        alt: `Computer chip`
      },
      points: [
        `Used by large corporations`,
        `Concerned about E&O risks`,
        `Handles a lot of customer data`
      ],
      description: `This technology consultant firm positions themselves as a total solution provider by offering bundled services
      to large corporations. Needing to protect their balance sheet, they require significant Errors and Omissions (E&O) insurance
      to respond on their behalf to third-party claims alleging financial or economic injury. We are able to seamlessly add E&O
      liability insurance, with a dedicated limit of insurance, to their General Liability policy, and integrate with cyber insurance
       to reduce gaps in their program.`,
    }
  ];
  slideOptions = { items: 1, dots: true, nav: true, onChange: this.owlChanged, loop: true };
  state = 'hide';
  slideDone = false;
  offset = 0;
  videos = [
    {
      id: 'video-craftsmen',
      href: `assets/videos/19021_IP_SM_Final.mp4`,
      type: `video/mp4`,
      poster: `assets/img/vid-still.jpg`,
      title: `Craftsmen of Insurance`,
      description: `Meet Chubb Technology Industry Practice Leader Veronica Somarriba, sharing, in her words, the expertise and commitment to excellence that goes into serving every Chubb client.`,
      share: {
        email_id: '10_1',
        web_root: ``,
        url: `https://bcove.video/2PjbPR1`,
        social_title: `Chubb's Entertainment Expertise`,
        social_body: 'Meet Chubb EVP Head of Technology Veronica Somarriba, sharing, in her words, the expertise and commitment to excellence that goes into serving every Chubb client.',
        email_subject: `Watch Craftsmen of Insurance℠ - Veronica's Story`,
        email_body: 'Meet Chubb EVP Head of Technology Veronica Somarriba, sharing, in her words, the expertise and commitment to excellence that goes into serving every Chubb client.',
        color: 'btn--white'
      }
    }
  ];

  ngOnInit() {
  }

  owlChanged(e) {
    if (e.item.index > 0) {
      window.dataLayer.push({
        'event': 'carouselEvent',
        'eventCategory': 'Carousel Click',
        'eventAction': 'Clicked on carousel control',
        'eventLabel': 'Slide ' + e.item.index
      });
    }
  }

  @HostListener('window:scroll', ['$event']) checkScroll() {
    const ne = this.el.nativeElement,
          direction = window.pageYOffset > this.offset ? 'down' : 'up';

    this.offset = window.pageYOffset;

    if (window.pageYOffset >= ne.offsetTop - ne.offsetHeight / 3 && window.pageYOffset <= ne.offsetTop + ne.offsetHeight) {
      if (direction === 'down') {
        this.state = 'show';
      } else {
        this.state = 'fadein';
      }
    } else {
      this.state = 'hide';
    }
  }

  scrollStart(el) {
    const ne = this.el.nativeElement,
      hr = el.element.querySelectorAll('hr'),
      li = el.element.querySelectorAll('li');

    if (window.pageYOffset < (ne.offsetTop - 100) || window.pageYOffset > (ne.offsetTop + ne.offsetHeight + 100)) {
      hr.forEach(h => {
        h.style.transition = 'none';
        h.style.opacity = '0';
      });
      li.forEach(e => {
        e.style.transition = 'none';
        e.style.opacity = '0';
      });
    }
  }

  scrollDone(el) {
    const hr = el.element.querySelectorAll('hr'),
      li = el.element.querySelectorAll('li');

    setTimeout(() => {
      hr.forEach(h => {
        h.style.transition = 'all 500ms ease-in';
        h.style.opacity = '1';
      });
      setTimeout(() => {
        li.forEach(e => {
          e.style.transition = 'all 700ms ease-in';
          e.style.opacity = '1';
        });
      }, 250);
    }, 250);
  }
}
