import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-find-agent',
  templateUrl: './find-agent.component.html',
  styleUrls: ['./find-agent.component.scss']
})
export class FindAgentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
