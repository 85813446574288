<article>
  <div class="parallax__wrap" [@scrollAnimation]="state" (@scrollAnimation.start)="scrollStart($event)" (@scrollAnimation.done)="scrollDone($event)">
    <section class="parallax__content">
      <h1 class="is-disabled">Empower yourself</h1>
      <hr />
      <!-- <app-webinar></app-webinar> -->
      <app-calculator></app-calculator>


      <section class="report">
        <div class="report--container r-table">

          <div class="col">
            <div class="col--img"><img src="assets/img/bi-tool@2x.png" alt="Business Income Consultation" title="Business Income Consultation" /></div>
            <div class="col--desc">
              <h3>Business Income Consultation</h3>
              <p>Our business income consultation tool is customized to allow technology companies to calculate an adequate level of business income insurance. Access the tool, select “All Other,” and do a quick calculation.</p>
              <div class="btn-group">
                <a href="https://chubbweb.chubb.com/chubbtraining/myBI.html" target="_blank" data-cta="Business Income Consultation" title="Business Income Consultation" class="btn">Launch the tool
                  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" style="margin-left:0.5rem;" viewBox="0 0 10.766 18.703"><defs><style>.a{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:2px;}</style></defs><path class="a" d="M0,0,8.645,8.645,0,17.289" transform="translate(0.707 0.707)"/></svg>
                </a>
                <app-btn-share
                  email_id="16_1"
                  web_root=""
                  url="https://chubbweb.chubb.com/chubbtraining/myBI.html"
                  social_title="Quick business income consultation."
                  social_linkedin="Are your clients adequately protected from potential business income losses? Check out our new BI Tool to find out:"
                  social_body="Are your clients adequately protected from potential business income losses? Check out our new BI Tool to find out: "
                  email_subject="Quick business income consultation."
                  email_body="Are your clients adequately protected from potential business income losses? Check out our new BI Tool to find out:"
                ></app-btn-share>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="col--img"><img src="assets/img/Technology@2x.jpg" alt="Technology: Navigating the future" title="Technology: Navigating the future" /></div>
            <div class="col--desc">
              <h3>Technology: Navigating the future</h3>
              <b>Presented by:</b>
              <p>Veronica Somarriba, EVP, Technology Industry Practice Leader</p>
              <p>Tom Stewart, Exec. Director of the National Center for Middle Market</p>
              <div class="btn-group">
                <a href="https://attendee.gotowebinar.com/register/4492736849594598915" target="_blank" data-cta="Register to access the recording" title="Register to access the recording" class="btn">Register to access the recording
                  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" style="margin-left:0.5rem;" viewBox="0 0 10.766 18.703"><defs><style>.a{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:2px;}</style></defs><path class="a" d="M0,0,8.645,8.645,0,17.289" transform="translate(0.707 0.707)"/></svg>
                </a>
              </div>
            </div>
          </div>

        </div>
      </section>


      <section class="report">
        <div class="report--container">
          <div class="report--content r-table">
            <app-report
              ngClass="col--alt"
              *ngFor="let report of reports"
              [thisReport]="report"
            ></app-report>
          </div>
        </div>
      </section>
    </section>
  </div>
</article>
