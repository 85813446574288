/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./claims-promise.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../btn-watch/btn-watch.component.ngfactory";
import * as i3 from "../btn-watch/btn-watch.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../node_modules/ngx-owl-carousel/src/owl-carousel.component.ngfactory";
import * as i6 from "ngx-owl-carousel/src/owl-carousel.component";
import * as i7 from "./claims-promise.component";
var styles_ClaimsPromiseComponent = [i0.styles];
var RenderType_ClaimsPromiseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClaimsPromiseComponent, data: {} });
export { RenderType_ClaimsPromiseComponent as RenderType_ClaimsPromiseComponent };
function View_ClaimsPromiseComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "videoPlayIconTemplate para-blue"], ["title", "Watch the video"]], [[1, "data-video-title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.playVideo(_v.parent.context.$implicit.video_url, "video/youtube", _v.parent.context.$implicit.background) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, ":svg:svg", [["class", "play-icon"], ["viewBox", "-7164.677 317 17.068 17.068"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, ":svg:g", [["class", "play-icon-1"], ["data-name", "Path 2174"], ["id", "Path_2174"], ["transform", "translate(-7153.452 319.693) rotate(45)"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:path", [["class", "play-icon-2"], ["d", "M-3.809,3.178,8.262,0,5.08,12.067Z"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:path", [["class", "play-icon-3"], ["d", "M 4.556581020355225 10.12926578521729 L 6.857667922973633 1.403813600540161 L -1.870922088623047 3.701776504516602 L 4.556581020355225 10.12926578521729 M 5.079821586608887 12.06672286987305 L -3.808988571166992 3.177932977676392 L 8.26207160949707 2.865448095690226e-06 L 5.079821586608887 12.06672286987305 Z"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.title, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ClaimsPromiseComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_ClaimsPromiseComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-btn-watch", [["title", "Watch the video"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.playVideo(_v.parent.context.$implicit.video_url, "video/youtube", _v.parent.context.$implicit.background) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_BtnWatchComponent_0, i2.RenderType_BtnWatchComponent)), i1.ɵdid(1, 114688, null, 0, i3.BtnWatchComponent, [], { title: [0, "title"] }, null)], function (_ck, _v) { var currVal_0 = "Watch the video"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ClaimsPromiseComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "r-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "r-col r-col--bg"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i4.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "background-image": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClaimsPromiseComponent_2)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "r-col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "r-col--content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClaimsPromiseComponent_3)), i1.ɵdid(12, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClaimsPromiseComponent_4)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, (("url(" + _v.context.$implicit.background) + ")")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.video_url; _ck(_v, 6, 0, currVal_1); var currVal_3 = _v.context.$implicit.description; _ck(_v, 12, 0, currVal_3); var currVal_4 = _v.context.$implicit.video_url; _ck(_v, 14, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.title; _ck(_v, 10, 0, currVal_2); }); }
function View_ClaimsPromiseComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal vid-modal"], ["id", "modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "modal__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "modal__close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["vid", 1]], null, 1, "video", [["class", "video-js vjs-default-skin vjs-16-9 vjs-big-play-centered"], ["id", "vid"]], [[8, "poster", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "source", [["type", "video/youtube"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.background; _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.video_url; _ck(_v, 5, 0, currVal_1); }); }
function View_ClaimsPromiseComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClaimsPromiseComponent_6)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.video_url; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ClaimsPromiseComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { vid: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "section", [["class", "parallax__content claims-promise clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Honoring our claims promise"])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "owl-carousel", [], null, null, null, i5.View_OwlCarousel_0, i5.RenderType_OwlCarousel)), i1.ɵdid(5, 311296, null, 0, i6.OwlCarousel, [i1.IterableDiffers], { carouselClasses: [0, "carouselClasses"], options: [1, "options"] }, null), i1.ɵpad(6, 3), i1.ɵpod(7, { items: 0, dots: 1, nav: 2, onChange: 3, loop: 4 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ClaimsPromiseComponent_1)), i1.ɵdid(9, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClaimsPromiseComponent_5)), i1.ɵdid(11, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 6, 0, "owl-theme", "sliding", "clearfix"); var currVal_1 = _ck(_v, 7, 0, 1, true, true, _co.owlChanged, true); _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_2 = _co.panels; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.panels; _ck(_v, 11, 0, currVal_3); }, null); }
export function View_ClaimsPromiseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-claims-promise", [], null, [["document", "click"], ["document", "keydown.escape"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).clickout($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keydown.escape" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onKeydownHandler($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_ClaimsPromiseComponent_0, RenderType_ClaimsPromiseComponent)), i1.ɵdid(1, 114688, null, 0, i7.ClaimsPromiseComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClaimsPromiseComponentNgFactory = i1.ɵccf("app-claims-promise", i7.ClaimsPromiseComponent, View_ClaimsPromiseComponent_Host_0, { vidObj: "vidObj" }, {}, []);
export { ClaimsPromiseComponentNgFactory as ClaimsPromiseComponentNgFactory };
